.header {
  position: sticky;
  top: 0vh;
  z-index: 10;
  height: 120px;
}

.body {
  padding: 0 8% 5rem 8%;
  min-height: calc(100vh - 120px);
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .body {
    padding: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .header {
    height: 100px;
  }

  .body {
    padding: 0rem 1rem;
  }
}
