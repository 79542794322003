.container {
  padding: 1.5rem;
}

.container > h4 {
  font-weight: 800;
  font-size: 22px;
  line-height: 140%;
  margin-bottom: 1rem;
  color: #ffd91b;
}

.container > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 1rem;
}

.privacyNoticeContainer > div {
  /* border: 2px solid red; */
  margin-top: 1rem;
}

.privacyNoticeContainer > div > p :not(.lastNote) {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 0.5rem;
}

.privacyNoticeContainer > div > ul > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 0.5rem;
}

.privacyNoticeContainer > div > ul > li {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 0.5rem;
}

.privacyNoticeContainer > div > ul > p {
  font-weight: 800;
  margin-top: 0.5rem;
}

.privacyNoticeContainer > div > ul > li {
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.privacyNoticeContainer {
  margin-top: 2rem;
}

.privacyNoticeContainer > div > h4 {
  font-weight: 800;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 1.5rem;
}

.privacyNoticeContainer
  > div
  > h4:not(.privacyNoticeContainer > div:nth-child(1) > h4) {
  margin: 1.5rem 0;
}

.lastNote {
  margin-top: 4rem;
  text-align: center;
  font-size: 14px;
}

.info > ul > p:nth-child(2),
.info > p:not(.info > p:nth-child(1)) {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin: 1rem 0;
}
