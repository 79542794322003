.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0.5rem;

  border-radius: 5px;
  margin: 1rem 0;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  overflow-x: hidden;
}
